























































































import {Vue,Component} from 'vue-property-decorator'
import {ObtenerPlanta, Planta} from '@/entidades/Maestro/Planta';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators'
import {Action} from 'vuex-class';
import Alerta from '@/entidades/Sistema/Alerta';
import moment from 'moment';
import {TurnoRequest,RegistrarTurno} from '@/entidades/KTB/RegistroInformacion/Turno';
import Loading from '@/entidades/Sistema/Loading';
@Component
export default class TurnoAdd extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    plantaId:number =1;
    descripcion:string ="";
    horaInicio:string ="";
    horaFin:string="";
    listadoPlanta:Array<Planta> = [];
    menu1:boolean=false;
    dates:Array<string> = [];


    //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
         horaInicio : {required},
         horaFin : {required},
         dates : {required},
         plantaId:{required}
     }

    async ObtenerPlant()
    {
        try
        {
            let rsp = await ObtenerPlanta();
            if(rsp.isSuccess == true)
                this.listadoPlanta = rsp.isResult;
        }
        catch(error)
        {

        }
    }

    async guardar()
    {
        //validas que se tenga ambas fechas
        if(this.dates.length < 2)
        {   
            this.changeAlerta(new Alerta("Debe seleccionar un rango de fecha...",true,"danger",2000));
            return;
        }
        var fecha1 = moment(this.dates[0]).format("YYYY/MM/DD");
        var fecha2 = moment(this.dates[1]).format("YYYY/MM/DD");
        
        var fechaInicio = fecha1 > fecha2 ? fecha2 : fecha1;
        var fechaFin = fecha1 > fecha2 ? fecha1 : fecha2;
        try
        {
            this.changeLoading(new Loading(true,"Registrando informacion"));
            let turno:TurnoRequest = new TurnoRequest(this.plantaId,this.descripcion,fechaInicio,fechaFin,
            this.horaInicio,this.horaFin);

            let rsp = await RegistrarTurno(turno);
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"success",2000));
                this.$emit('limpiar');
                
            }
            else
            {
               this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));     
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }


    get dateRangeText()
    {
        return this.dates.join(' ~ ')
    }

    mounted() {
        this.ObtenerPlant();   
    }
}
